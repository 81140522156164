function formatNumber(num) {
    // eslint-disable-next-line security/detect-unsafe-regex
    return num.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1 ")
}

window.formatNumber = formatNumber

nifty.document.ready(function () {
    if($('.registrar-stats').length > 0) {
        $.get($('.registrar-stats').attr("data-json-url-template").replace("dateholder", moment().format('YYYY-MM-DD')))
            .done(function (data) {
                $('.stats-value').html(0);
                $('.stats-hidden').show();
                for (let i in data) {
                    if (i.match(/^CREATE_DOMAIN_\d{1,2}_YEAR(S)?(_IDN)?$/)) {
                        if (i.endsWith('_IDN')) {
                            // eslint-disable-next-line security/detect-object-injection
                            updateTotal($("#stats-CREATE_DOMAIN_IDN"), data[i]);
                        } else {
                            // eslint-disable-next-line security/detect-object-injection
                            updateTotal($("#stats-CREATE_DOMAIN"), data[i]);
                        }
                        continue;
                    }
                    if (i.match(/^RENEW_AT(_NO)?_COST(_IDN)?$/)) {
                        if (i.endsWith('_IDN')) {
                            // eslint-disable-next-line security/detect-object-injection
                            updateTotal($("#stats-RENEW_IDN"), data[i]);
                        } else {
                            // eslint-disable-next-line security/detect-object-injection
                            updateTotal($("#stats-RENEW"), data[i]);
                        }
                        continue;
                    }
                    /*if (i.startsWith("REACTIVATE_DOMAIN")) {
                        updateTotal($("#stats-REACTIVATE"), data[i]);
                        continue;
                    }*/
                    if (i.match(/^(IMMEDIATE_)?QUARANTINE(_BY_JOB)?_DOMAIN(_IDN)?$/)) {
                        if (i.endsWith('_IDN')) {
                            // eslint-disable-next-line security/detect-object-injection
                            updateTotal($("#stats-DELETE_IDN"), data[i]);
                        } else {
                            // eslint-disable-next-line security/detect-object-injection
                            updateTotal($("#stats-DELETE"), data[i]);
                        }
                        continue;
                    }
                    if (i === "REGISTRAR_PORTFOLIO_IDN") {
                        // eslint-disable-next-line security/detect-object-injection
                        updateTotal($("#stats-TOTAL_ACTIVE_IDN"), data[i]);
                        continue;
                    }
                    if (i === "REGISTRAR_PORTFOLIO") {
                        // eslint-disable-next-line security/detect-object-injection
                        updateTotal($("#stats-TOTAL_ACTIVE"), data[i]);
                        continue;
                    }
                    if (i === "REGISTRAR_PORTFOLIO_CYRILLIC_TLD") {
                        // eslint-disable-next-line security/detect-object-injection
                        updateTotal($("#stats-TOTAL_ACTIVE_CYRILLIC"), data[i]);
                        continue;
                    }
                    if(i === "REGISTRAR_PORTFOLIO_GREEK_TLD") {
                        // eslint-disable-next-line security/detect-object-injection
                        updateTotal($("#stats-TOTAL_ACTIVE_GREEK"), data[i]);
                        continue;
                    }
                    let $row = $(".whats-happening-row").clone();
                    $row.appendTo("#whats-happening").removeClass('whats-happening-row');
                    // eslint-disable-next-line security/detect-object-injection
                    $row.find('.whats-happening-count').html(formatNumber(data[i]));
                    $row.find('.whats-happening-text').html(i.replace(/_/g, " "));
                    $row.css('display', 'block');
                    $("#whats-happening > li").sort(asc_sort).appendTo('#whats-happening');
                    $(".ajax-loading").hide();
                }
            })
            .fail(function () {
                $('.stats-value').html("-");
                $(".ajax-loading").hide();
            });
    }
});

function updateTotal($elt, value) {
    let old_total = 0
    if(typeof $elt.html() !== "undefined") {
        old_total = parseInt($elt.html().replace(/ /g, ''));
    }
    $elt.html(formatNumber((isNaN(old_total) ? 0 : old_total) + value));
}

function asc_sort(a, b) {
    return ($(b).find('.whats-happening-text').text()) < ($(a).find('.whats-happening-text').text()) ? 1 : -1;
}