import {Calendar} from '@fullcalendar/core'
import bootstrapPlugin from '@fullcalendar/bootstrap'
import dayGridPlugin from '@fullcalendar/daygrid'
import interactionPlugin from '@fullcalendar/interaction'

let MAP_PLUGINS = {
    'bootstrap': bootstrapPlugin,
    'dayGrid': dayGridPlugin,
    'interaction': interactionPlugin
}

let createCalendar = (el, options, plugins=[]) => {
    // eslint-disable-next-line security/detect-object-injection
    options['plugins'] = plugins.map((d) => MAP_PLUGINS[d])
    return new Calendar(el, options)
}

window.Calendar = {createCalendar: createCalendar}