/**
 * Created by hugos on 6/27/17.
 */
!function ($) {
    "use strict";

    window.nifty = {
        'window': $(window),
        'body': $('body'),
        'document': $(document)
    };
}(jQuery);