import L from "leaflet";

function load_map(dom_element, address_data){
    address_data['addressdetails'] = 1;
    address_data['format']= 'json';
    $.get('//nominatim.openstreetmap.org/search', address_data, function (res) {
        if (res.length > 0){
            $(dom_element).css('height', '500px');
            let first = res[0];
            let lat = first.lat;
            let lon = first.lon;
            let zoom = 18;

            let map = new L.Map(dom_element, {scrollWheelZoom: false});
            // Disable scroll zooming and bind back the click event
            map.once('focus', function() { map.scrollWheelZoom.enable(); });

            let osmUrl='//{s}.tile.openstreetmap.org/{z}/{x}/{y}.png';
            let osmAttrib='Map data © <a href="http://openstreetmap.org">OpenStreetMap</a> contributors';
            let osm = new L.TileLayer(osmUrl, {minZoom: 8, maxZoom: 18, attribution: osmAttrib});
            map.setView(new L.LatLng(lat, lon),zoom);
            map.addLayer(osm);

            let marker = L.marker([lat, lon]).addTo(map);

            let popup_text = "{road} {house_number}<br>{postcode} {place}<br>{country}";

            let place_order = ['city_district', 'city', 'village', 'town'];
            let place_description = '';
            for (let i in place_order){
                // eslint-disable-next-line security/detect-object-injection
                let place = place_order[i];
                if ( place in first.address){
                    place_description = place;
                    break;
                }
            }
            for (let item in first.address){
                // eslint-disable-next-line security/detect-object-injection
                popup_text = popup_text.replace('{' + item + '}', first.address[item]);
            }
            // eslint-disable-next-line security/detect-object-injection
            popup_text = popup_text.replace('{place}', first.address[place_description]);
            popup_text = popup_text.replace(/\{.*?\}/g, '');
            popup_text = $.trim(popup_text).replace(/^<br>/, '');
            marker.bindPopup(popup_text).openPopup();
        }else{
            if ('street' in address_data){
                delete address_data['street'];
                load_map(dom_element, address_data);
            }else{
                $(dom_element).html('<div class="alert alert-warning">The address could not be found.</div>');
            }
        }
    }, 'json');
}

nifty.document.ready(function () {
    L.Icon.Default.imagePath = '/static/dist/img/leaflet/';

    $('.map').each(function(){
        let gdata = $(this).data('contact');
        if (gdata === null || gdata === undefined) {
            return;
        }
        let contact = JSON.parse(decodeURIComponent(gdata));

        for (let i in contact){
            // eslint-disable-next-line security/detect-object-injection
            if (contact[i] === null){
                // eslint-disable-next-line security/detect-object-injection
                contact[i] = '';
            }
        }

        let addressData = {
            'street': contact.street1 + ' ' + contact.street2 + ' ' + contact.street3,
            'postalcode': contact.postalCode,
            'city': contact.city,
            'country': contact.country
        };

        load_map(this, addressData);
    });
});
