import ClipboardJS from "clipboard"

nifty.document.ready(function () {
    $('#copy-clipboard-content').focus(function () {
        let $this = $(this);

        $this.select();

        window.setTimeout(function () {
            $this.select();
        }, 1);

        // Work around WebKit's little problem
        $this.mouseup(function () {
            // Prevent further mouseup intervention
            $this.unbind("mouseup");
            return false;
        });
    });
    $('.copy-clipboard-modal-trigger').on('click', function () {
        $('#copy-clipboard-content').val($(this).data('clipboard-value'));
        $("#clipboard-modal").modal("show");
        window.setTimeout(function () {
            $('#copy-clipboard-content').focus();
        }, 500);
    });

    $('.copy-clipboard-trigger-link').on('click', function(){
      let el = document.createElement('textarea');
      el.value = $(this).attr('data-value');
      document.body.appendChild(el);
      el.select();
      document.execCommand('copy');
      document.body.removeChild(el);
    });
    new ClipboardJS('.copy-clipboard-trigger');
});